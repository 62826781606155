import { BundleModifyTypes, PurchaseTypes } from "../app/shared/types/flows-config.types";
import { BundleEditionTypes } from "../app/shared/types/order.type";

const base_api = "https://motherearth-staging.navego.tech";

export const environment = {
  production: false,
  apis: {
    v1: `${base_api}`,
    v2: `${base_api}/api/v2`,
    v3: `${base_api}/api/v3`,
    google: {
      places: 'AIzaSyBTHTviZ7QPtEjH29Jmmkp13RO8LPrxppM'
    },
    navego: {
      url: 'https://apis.navego.tech/api',
      key: 'dsfgrdf125fdffasd677rrfgr',
      clientNr: '4'
    },
    firebase: {
      apiKey: "AIzaSyDCKHBa35tEsUmZNhfdG4EEWhg6Y-ZNeZQ",
      authDomain: "dev-me-c8559.firebaseapp.com",
      projectId: "dev-me-c8559",
      storageBucket: "dev-me-c8559.appspot.com",
      messagingSenderId: "621833762579",
      appId: "1:621833762579:web:b9d8b065b47170c5448ce6",
      measurementId: "G-FJ9RRFMPZ6"
    },
    fireauth: {
      email: 'javier.franco@eliostudios.co',
      password: '@N4v3g0.T3ch.2024**'
    },
    klaviyo: {
      publicApiKey: ''
    },
    maintenance: {
      url: 'https://ecommerce-configurations.navego.tech/api/v3/maintenance?url=motherearth-testing.com'
    },
    authorize: {
      APILoginID: '859vpT8YqF',
      PublicClientkey: '2789LLpawLnj4TJe2s5k7akamxnXS3aTq442sEs4vkU82JnTQ56e93fNuMUXmfC6'
    }
  },
  application: {
    angular: 'Angular 17.3.7',
    bootstrap: 'Bootstrap 5.3.2',
    fontawesome: 'Font Awesome 6.5.1',
    name: 'Mother Earth',
    version: '1.0.0',
  },
  config: {
    bodyClass: 'app-me',
    crypto: {
      key: '',
    },
    flows: {
      territories: false,
      membership: false,
      settings: {
        toHide: {
          membership: 'Membership'
        },
        profile: {
          hasPickupLocationsFlow: true
        },
        deliveryDayUpdates: {
          displayOnlyActiveReescheduledOrder: true
        }
      },
      specialMembership: {
        defaultRoute: '/order/custom-box/' + BundleEditionTypes.subscription,
        settings: {
          hideHeaderCategoriesNav: true
        },
      },
      order: {
        askForDonationOnSkip: false,
        showFreeDeliverySlider: true,
        voluntaryDonationFlow: false,
      },
      bundles: {
        modifyType: BundleModifyTypes.outOfOrder
      },
      shop: {
        validateMarketState: false,
        displayCategoryName: false
      },
      login: {
        tempResetPassword: true
      },
      purchase: {
        type: PurchaseTypes.anet
      }
    },
    filesVersionNumber: {
      logo: 2
    },
    friendReferral: {
      baseUrl: '3.19.44.52:8082/signup?utm_source=friend_referral&utm_medium=share&utm_campaign='
    },
    seo: {
      mainTitle: 'Mother Earth Food Nourishing communities, cultivating connection | Mother Earth Food',
      mainDescription: 'Discover Mother Earth Food: Committed to sustainable local and regional food. Bringing farmers market freshness to your doorstep with home delivery convenience',
      baseTitle: 'Mother Earth Food: Sustainable Local Delights Delivered Year-Round',
      baseKeywords: ['Sustainable', 'Local', 'Regional', 'Home Delivery', 'Fresh Produce', 'Online Ordering', 'Mother Earth', 'Food'],
      type: 'website',
      baseURL: 'https://www.motherearthfood.com/'
    },
    contactEmail: 'terra@motherearthfood.com'
  },
};
