import { inject, Injectable } from '@angular/core';
import { NotificationService } from '../notification/notification.service';
import { Observable, of, throwError } from 'rxjs';
import { HTTP_STATUS_CODES } from '../constants/errors';
import { Location } from '@angular/common';
import { AppService } from '../../app.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {

  private isSessionExpired: boolean = false;
  private preventMessage: boolean = false;
  private location = inject(Location);
  private appService = inject(AppService);

  constructor(private notificationService: NotificationService) { }

  handleHttpError(
    error: any,
    returnNull: boolean = true,
    showErrorMessage: boolean = true,
    returnError: boolean = false
  ): Observable<any> {
    this.preventMessage = false;
    if (this.isSessionExpired) return of(null);

    let httpError = '';
    switch (error.status) {
      case HTTP_STATUS_CODES.NOT_FOUND:
        httpError = 'Not Found';
        break;
      case HTTP_STATUS_CODES.TOO_MANY_REQUESTS:
        httpError = 'Too Many Requests';
        break;
      case HTTP_STATUS_CODES.SERVER_ERROR:
        this.appService.getMaintenanceState(true);
        this.preventMessage = true;
        break;
      case HTTP_STATUS_CODES.UNKNOWN_ERROR:
        this.preventMessage = true;
        break;
      case HTTP_STATUS_CODES.I_AM_A_TEAPOT:
        if (window?.location)
          this.location.replaceState('/');
        window.location.reload();
        break;
      case HTTP_STATUS_CODES.SUSPENDE_USER:
        httpError = 'Suspended user';
        this.preventMessage = true;
        break;
      case HTTP_STATUS_CODES.SESSION_EXPIRED:
        this.isSessionExpired = true;
        this.preventMessage = true;
        setTimeout(() => {
          localStorage.clear();
          this.location.replaceState('/');
          window.location.reload();
        }, 1000);
        break;
      default:
        break;
    }

    const unknownError = 'Service unavailable error';
    const errorMessagesFromResponse = error?.error?.message ? (showErrorMessage ? error?.error?.message : -1) : httpError || null;

    if (errorMessagesFromResponse === -1) return returnNull ? of(null) : returnError ? of({ data: error.error.data ?? null, message: error.error.message || 'Internal Error', error: true, status: error.status || 0 }) : throwError(() => error);

    let text: any = errorMessagesFromResponse || unknownError
    if (!this.isSessionExpired && !this.preventMessage)
      this.notificationService.show({ text, type: 'error' });

    return returnNull ? of(null) : returnError ? of({ data: error.error.data ?? null, message: error.error.message || 'Internal Error', error: true, status: error.status || 0 }) : throwError(() => error);
  }
}