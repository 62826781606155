<div class="form-floating has-validation">
  <input #autocompleteInput (ngModelChange)="changeAddress($event)" class="form-control" (focusout)="onFocusOut($event)"
    [class.is-invalid]="!isLoadingContent() && ((!hasDeliveryAddressCoverage() && isGoogleAddressFilled()) || controlHasError())"
    [(ngModel)]="queryString" [class.is-valid]="!isLoadingContent() && hasDeliveryAddressCoverage()" id="address"
    placeholder (input)="changeAddress($event)">
  <label for="address">{{label()}}</label>
  @if (isLoadingContent()) {
  <mat-progress-bar mode="indeterminate" value="accent"></mat-progress-bar>
  } @else {
  @if (hasDeliveryAddressCoverage()) {
  <div class="valid-tooltip">
    This address is in our delivery area!
  </div>
  } @else if (isGoogleAddressFilled()) {
  <div class="invalid-tooltip">
    Oops! Your address isn't in our delivery zone.
  </div>
  } @else if(controlHasError()) {
  <div class="invalid-tooltip">
    You should select a valid address.
  </div>
  }
  }
</div>