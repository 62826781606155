<svg class="animated-check" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <!-- MAIN CIRCLE -->
    <circle class="circle-large" cx="50" cy="50" r="30" [class.error]="isError()" [class.success]="isSuccess()" />

    <!-- CIRCLES FOR PULSE ANIMATION WHEN LOADING -->
    @if (!isFinished()) {
    <circle class="circle-pulse-7" cx="50" cy="50" r="44" />
    <circle class="circle-pulse-6" cx="50" cy="50" r="42" />
    <circle class="circle-pulse-5" cx="50" cy="50" r="40" />
    <circle class="circle-pulse-4" cx="50" cy="50" r="38" />
    <circle class="circle-pulse-3" cx="50" cy="50" r="36" />
    <circle class="circle-pulse-2" cx="50" cy="50" r="34" />
    <circle class="circle-pulse-1" cx="50" cy="50" r="32" />
    }

    <!-- GROUP: ROTATING ARC -->
    <g class="rotating-arc" [class.success]="isSuccess()" [class.error]="isError()">
        <!-- INNER ARC -->
        <path class="arc" d="M25 50 A 25 25, 0, 1, 1, 75 50" />
    </g>

    <!-- SMALL INNER CIRCLE WHICH STARTS THE X-MARK OR CHECK-MARK DRAW -->
    <circle class="circle-small" cx="50" cy="30" r="2" [class.success]="isSuccess()" [class.error]="isError()" />

    <!-- CHECK MARK - ON SUCCESS RESPONSE -->
    <path class="check-mark" [class.show]="isSuccess()" d="M35 50l10 10l20-20" />

    <!-- X-MARK - ON ERROR RESPONSE -->
    <path class="x-mark" [class.show]="isError()" d="M40 40 L60 60 M60 40 L40 60" />


</svg>